/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Myproperties() {
  const [data, setData] = useState([]);
  const GetData = () => {
    axiosInstance
      .get(`/listing`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handledelete = async (id) => {
    await axiosInstance
      .get(`/listing-status-change/${id}`)
      .then((res) => {
        // console.log(res,'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {data?.length === 0 && (
        <div className="text-center">
          <h3>No properties found.</h3>
        </div>
      )}
      <div className="newnew">
        {data?.map((e, i) => {
          return (
            <div className="property-item col-mdd-6">
              <div className="property--img">
                <a href="#">
                  <img
                  style={{height:"270px"}}
                    src={e?.listing_images?.[0]?.image}
                    alt="property image"
                    className="img-responsive"
                  />
                  <span className="property--status">For Rent</span>
                </a>
              </div>
              <div className="property--content">
                <div className="property--info">
                  <h5 className="property--title">
                    <a href="#">{e?.title}</a>
                  </h5>
                  <p className="property--location">
                    {" "}
                    <i className="fa fa-map-marker" />
                    {` ${e?.city},${e?.district},  ${e?.region}` }
                  </p>
                  <div className="flex11">
                       <p className="property--price">GH₵ {e?.price} <sup >/month</sup></p>
                    <div style={{ display: "flex11" }}>
                      <button
                        className="bg-black p-xs border-none button-detail"
                        onClick={() =>
                          navigate(`/en-gh/editproperty/${e?.id}`, { state: e })
                        }
                      >
                        <i className="fa fa-edit" style={{ color: "white" }} />
                      </button>
                      <button
                        className="bg-black p-xs ml-xs border-none button-detail"
                        onClick={() => handledelete(e?.id)}
                      >
                        {e?.is_active === 0 ? (
                          <i class="fa fa-lock"></i>
                        ) : (
                          <i class="fa fa-unlock"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="property--features">
                  {/* <p>{e?.description.slice(0, 83)}</p> */}
                  <div className="flex1">
                    {/* <div className="">
                                     <img
                                       className="mtt-10"
                                       src="https://dve7rykno93gs.cloudfront.net/assets4/img/garage-icon.png"
                                       alt=""
                                     />
                                     <span className="ml-sm bold font-20 text-black ">
                                       1
                                     </span>
                                   </div> */}
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/shower-icon.png"
                        alt=""
                      />
                      <span className="ml-sm bold font-20 text-black ">
                        {e?.no_of_bathroom}
                      </span>
                    </div>
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/bed-icon.png"
                        alt=""
                      />
                      <span className="ml-sm bold font-20 text-black ">
                        {e?.no_of_bedroom}
                      </span>
                    </div>
                    <div className="">
                      <i class="fa fa-home"  style={{ fontSize: "20px" ,color:"#0099cc"}}></i>
                      <span className="ml-sm bold font-20 text-black ">
                        {e?.type}
                      </span>
                    </div>
                    {/* <div className="">
                                     <img
                                       className="mtt-10"
                                       src="https://dve7rykno93gs.cloudfront.net/assets2/images/area-icon.png"
                                       alt=""
                                     />
                                     <span className="ml-sm bold font-20 text-black ">
                                       400 m<sup>2</sup>
                                     </span>
                                   </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Myproperties;
