import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Addproperty from "./Pages/Addproperty";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Notfound from "./Pages/Notfound";
import Details from "./Pages/Details";
import Profile from "./Component/Profile";

import { GoogleOAuthProvider } from "@react-oauth/google";
import Verify from "./Component/Verify";
import Changepassword from "./Component/Changepassword";
import SingleProperty from "./Component/SingleProperty/SingleProperty";
import { ProtectedRoute } from "./Config/Notlogin";
import MyproHome from "./Component/MyProperty/MyProprtiesHome";
import Contact from "./Component/Contact/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Practise from "./Component/PRactise";
import EditProperty from "./Component/EditProperty";
import ContactPage from "./Pages/Contact";
import Favorite from "./Pages/Favorite/Favorite";
import About from "./Pages/About/About";
import CheapRoom from "./Pages/Cities/CheapRoom";
import Features from "./Pages/Feature/Feature";
import House from "./Component/Type/House";
import Apartment from "./Component/Type/Apartment";
import Room from "./Component/Type/Room";
import Index from "./Pages/Faqs";
import Indexs from "./Pages/Blog/Index";
import Careers from "./Pages/Careers/Careers";
import Blogdetail from "./Component/Blog/Blogdetail";
import Term from "./Pages/Term/Term";
import Rental from "./Pages/Rental/Rental";
import Privacy from "./Pages/Privacy/Privacy";
import Stores from "./Pages/Store/Stores";
import AuthProvider from "./Config/Context/Context";
import Searching from "./Component/PractiseSeraching/Index";
import Hostel from "./Component/Type/Hostel";

// import Practice from "./Component/PRactise";
// import ImageUploadWithPreview from "./Component/Practise.js";
// import ImageUploadWithPreview from "./Component/PRactise.js";

function App({ children }) {
  useEffect(() => {
    
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AuthProvider>
        <GoogleOAuthProvider clientId="994842147147-3t7grtobk5dh08h5k7osa5jfqvncbkf5.apps.googleusercontent.com">
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/en-gh" element={<Home />}></Route>
              <Route path="/" element={<Practise />}></Route>
              <Route path="/en-gh/searching" element={<Searching />}></Route>
              <Route path="/en-gh/hostel" element={<Hostel />}></Route>
              <Route
                path="/en-gh/addproperty"
                element={
                  <ProtectedRoute>
                    <Addproperty />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/en-gh/editproperty/:id"
                element={
                  <ProtectedRoute>
                    <EditProperty />
                  </ProtectedRoute>
                }
              ></Route>
              <Route path="/en-gh/listings" element={<Details />}></Route>
              <Route path="/en-gh/contact" element={<Contact />}></Route>
              <Route path="/en-gh/cheaproom" element={<CheapRoom />}></Route>
              <Route path="/en-gh/room" element={<Room />}></Route>
              <Route path="/en-gh/apartment" element={<Apartment />}></Route>
              <Route path="/en-gh/house" element={<House />}></Route>
              <Route path="/en-gh/faq" element={<Index />}></Route>
              <Route path="/en-gh/blog" element={<Indexs />}></Route>
              <Route
                path="/en-gh/blogdetail/:id"
                element={<Blogdetail />}
              ></Route>
              <Route path="/en-gh/feature" element={<Features />}></Route>
              <Route path="/en-gh/careers" element={<Careers />}></Route>
              <Route path="/en-gh/term" element={<Term />}></Route>
              <Route path="/en-gh/store" element={<Stores />}></Route>
              <Route path="/en-gh/rental_guides" element={<Rental />}></Route>
              <Route path="/en-gh/privacy" element={<Privacy />}></Route>
              <Route
                path="/en-gh/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/en-gh/mypro"
                element={
                  <ProtectedRoute>
                    <MyproHome />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/en-gh/fav-pro"
                element={
                  <ProtectedRoute>
                    <Favorite />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/en-gh/contacts"
                element={
                  // <ProtectedRoute>
                  <ContactPage />
                  // </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/en-gh/about"
                element={
                  // <ProtectedRoute>
                  <About />
                  // </ProtectedRoute>
                }
              ></Route>
              <Route path="/en-gh/verify" element={<Verify />}></Route>
              <Route path="/en-gh/change" element={<Changepassword />}></Route>
              {/* <Route path="/" element={<ImageUploadWithPreview />}></Route> */}
              <Route
                path="/en-gh/singlepro/:id"
                element={
                  // <ProtectedRoute>
                  <SingleProperty />
                  //  </ProtectedRoute>
                }
              ></Route>
              {/* <Route path="*" element={<Notfound />}></Route> */}
            </Routes>
          </BrowserRouter>
        </GoogleOAuthProvider>
        {children}
      </AuthProvider>
    </>
  );
}

export default App;
