import React from "react";

function Vedio({ data }) {
  return (
    <div className="property-single-video inner-box">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="heading">
            <h2 className="heading--title">Video</h2>
          </div>
        </div>
        {/* .col-md-12 end */}
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div dangerouslySetInnerHTML={{ __html: data?.link }} />
          {/* .video-content end */}
        </div>
        {/* .col-md-12 end */}
      </div>
      {/* .row end */}
    </div>
  );
}

export default Vedio;
