import React, { useState } from "react";

// Sample data
const data = [
  { city: "Chicago", region: "Illinois", country: "USA" },
  { city: "Cleveland", region: "Ohio", country: "USA" },
  { city: "Copenhagen", region: "Hovedstaden", country: "Denmark" },
  // Add more data as needed
];

const Searching = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = data.filter(
      (item) =>
        item.city.toLowerCase().includes(value) ||
        item.region.toLowerCase().includes(value) ||
        item.country.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search for cities, regions, or countries..."
      />
      <ul className={searchTerm ? "d-block" : "d-none"}>
        {filteredData.map((item, index) => (
          <li key={index}>
            {item.city}, {item.region}, {item.country}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Searching;
