/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import { axiosData } from "../Config/Axios";
// import { axiosInstance } from "../Config/AxiosInstance";
import { GetData, GetDataone, Getlisting } from "./Aminittes";
import Select from "react-select";
import { Slider } from "@mui/material";
// Import Swiper React components
import HeroSlider from "./HeroSlider";
// import { ProtectedRoute } from "../Config/Notlogin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../Config/Context/Context";

// import './styles.css';

// import required modules

// import required modules
// import { Pagination } from "swiper/modules";
const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black", // Replace with your desired color
  }),
  control: (provided) => ({
    ...provided,
    // Additional styles for the control if needed
  }),
};
const selectStylesone = {
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black", // Replace with your desired color
  }),
  control: (provided) => ({
    ...provided,
    // Additional styles for the control if needed
  }),
};
function Hero() {
  // const token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, setDistricts] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [citydata, setcitydata] = useState("");
  // const [selectedDistirct] = useState(null);
  // console.log(selectedCountry?.value);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [type, settype] = useState([]);
  const geonamesUsername = "sywyj";
  const [feature, setfeature] = useState([]);
  const [listing, setlisting] = useState([]);
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    Getlisting().then((e) => {
      const filter = e?.data?.data?.filter((e) => e?.city);
      setlisting(filter);
    });
    GetDataone().then((e) => {
      settype(e?.data?.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const countryOptions = [
  //   {
  //     value: 2300660,
  //     label: 'Ghana',
  //   },
  // ];

  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
        );
        const stateOptions = response.data.geonames.map((state) => ({
          value: state.geonameId,
          label: state.name,
        }));
        setStates(stateOptions);
      } catch (error) {
        setError("Error fetching states");
        console.error("Error fetching states:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedState.value}&username=${geonamesUsername}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          setError("Error fetching cities");
          console.error("Error fetching cities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    } else {
      setCities([]);
      setDistricts([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const fetchDistricts = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedCity.value}&username=${geonamesUsername}`
          );
          const districtOptions = response.data.geonames.map((district) => ({
            value: district.geonameId,
            label: district.name,
          }));
          setDistricts(districtOptions);
        } catch (error) {
          setError("Error fetching districts");
          console.error("Error fetching districts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedCity]);

  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  // console.log(selectedValues);
  const [value, setValue] = React.useState([100, 10000]);
  // console.log(value, "value");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (e) => {
    setSelectedValues({
      ...selectedValues,
      [e.target.name]: e.target.value,
    });
  };

  // Fetch data based on selected values

  const toggleOptions = () => {
    setOpen(!open);
  };
  // const token = localStorage.getItem("Token");
  let { setFilters } = useContext(AuthContext);
  const Onsubmit = (event) => {
    event.preventDefault();

    const Data = new FormData();
    const params = new URLSearchParams();

    if (selectedCity) {
      setFilters({
        selectedCity: selectedCity.value,
      });
      Data.append("byDistrict", selectedCity?.label);
      params.append("byDistrict", selectedCity?.label);
    }

    if (selectedState) {
      setFilters({
        selectedState: selectedState.value,
      });
      Data.append("byRegion", selectedState?.label);
      params.append("byRegion", selectedState?.label);
    }

    if (citydata) {
      Data.append("byCity", citydata);
      params.append("byCity", citydata);
      setFilters({
        cityTown: citydata,
      });
    }

    if (selectedValues?.type) {
      Data.append("byListingType", selectedValues?.type);
      params.append("byListingType", selectedValues?.type);
      setFilters({
        type: selectedValues?.type,
      });
    }

    if (selectedValues?.bath) {
      Data.append("byAmeneties", selectedValues?.bath);
      params.append("byAmeneties", selectedValues?.bath);
      setFilters({
        baths: selectedValues?.bath,
      });
    }

    Data.append("byMin", value[0]);
    Data.append("byMax", value[1]);

    axiosData
      .post("/listing-search", Data)
      .then((response) => {
        console.log("Form submitted successfully!", response);
        if (response.data) {
          const queryString = params.toString();
          const url = queryString
            ? `/en-gh/listings?${queryString}`
            : "/en-gh/listings";

          navigate(url, {
            state: response?.data?.listings,
          });

          toast.success(response?.data?.message);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // toast.error(error?.message);
        navigate("/en-gh/listings", {
          state: [],
        });
      });
  };
  const handleCity = (e) => {
    const value = e.toLowerCase();
    setcitydata(value);
    const filtered = listing?.filter(
      (item) =>
        item.city.toLowerCase().includes(value) ||
        item.region.toLowerCase().includes(value) ||
        item.country.toLowerCase().includes(value)
    );
    setSearching(filtered);
    if (!value) {
      setSearching([]);
    }
    // console.log(filtered);
  };

  return (
    <section id="heroSearch" className="hero-search mtop-100 pt-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="slider--content">
              <div className="text-center">
                <h1>Connecting Tenants and Landlords</h1>
              </div>
              <form className="mb-0">
                <div className="form-box search-properties">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <Select
                            placeholder="Select State/Region"
                            options={states}
                            styles={selectStyles}
                            onChange={setSelectedState}
                            isDisabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {selectedState && ( */}
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <Select
                            placeholder="Select District"
                            options={cities}
                            styles={selectStylesone}
                            onChange={setSelectedCity}
                          />
                        </div>
                      </div>
                    </div>
                    {/* // )} */}
                    {/* {selectedCity && selectedState && ( */}
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <input
                            placeholder="Enter City/Town"
                            type="text"
                            value={citydata}
                            onChange={(e) => handleCity(e?.target?.value)}
                            className={`form-control`}
                            name="suburb"
                            // disabled={!selectedCity || loading}
                            id="suburb"
                          />
                          <ul
                            className={`${
                              searching.length === 0
                                ? "d-none"
                                : "dropdown-menu1"
                            }`}
                          >
                            {searching &&
                              Array.from(
                                new Set(
                                  searching?.map((item) =>
                                    item.city?.toLowerCase()
                                  )
                                )
                              ).map((item) => (
                                <li
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                  }}
                                  key={item.id}
                                  onClick={() => {
                                    setcitydata(item);
                                    setSearching([]);
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <button
                        className="btn btn--primary btn--block"
                        onClick={Onsubmit}
                      >
                        Search
                      </button>
                    </div>
                    {/* .col-md-3 end */}
                    <div
                      className={`col-xs-12 col-sm-6 col-md-3  ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <select
                            name="type"
                            id="select-beds"
                            // value={selectedValues.selectBeds}
                            onChange={handleSelectChange}
                          >
                            <option>Any Type</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* .col-md-3 end */}
                    <div
                      className={`col-xs-12 col-sm-6 col-md-3 ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="form-group ">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <select
                            name="bath"
                            id="select-baths"
                            // value={selectedValues.selectBaths}
                            onChange={handleSelectChange}
                          >
                            <option>Features</option>
                            {feature.map((e) => {
                              return (
                                <option key={e.name} value={e.name}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* .col-md-3 end */}
                    <div
                      className={`col-xs-12 col-sm-6 col-md-6 ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="filter mb-30">
                        <p>
                          <label htmlFor="amount">
                            Price Range: GH₵ {value[0]} - GH₵ {value[1]}{" "}
                          </label>

                          {/* <input
                            id="amount"
                            type="range"
                            className="amount mt--20"
                            value={priceRangeValue}
                            onChange={handlePriceRangeChange}
                          /> */}
                          <Slider
                            getAriaLabel={() => "Temperature range"}
                            value={value}
                            className="amount"
                            onChange={handleChange}
                            min={100}
                            max={10000}
                            valueLabelDisplay="auto"
                            // getAriaValueText={valuetext}
                          />
                        </p>
                        <div className="slider-range" />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <a
                        href="#"
                        className={`less--options ${open ? "active" : ""}`}
                        onClick={toggleOptions}
                      >
                        {open ? "Less options" : "More options"}
                      </a>
                    </div>
                  </div>
                  {/* .row end */}
                </div>
                {/* .form-box end */}
              </form>
            </div>
          </div>
          {/* .container  end */}
        </div>
        {/* .slider-text end */}
      </div>
      <HeroSlider />
    </section>
  );
}

export default Hero;
