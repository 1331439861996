import { axiosData } from "../../Config/Axios";
import { axiosInstance } from "../../Config/AxiosInstance";

export const GetFavProperty = (setData) => {
    axiosInstance
      .get(`/listing-favourite`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export   const GetDataRoom = (filters,setLoader,filter1,setData,filter) => {
    setLoader(true);
    const queryParams = [];
    if (filters.cityTown) queryParams.push(`city=${filters.cityTown}`);
    if (filters.price) queryParams.push(`price=${filters.price}`);
    if (filters.rentPeriod)
      queryParams.push(`rent_period=${filters.rentPeriod}`);
    if (filters.availability)
      queryParams.push(`availability=${filters.availability}`);
    if (filters.beds) queryParams.push(`bed=${filters.beds}`);
    if (filters.baths) queryParams.push(`bath=${filters.baths}`);

    const stateLabel = filters?.selectedState
      ? filter[0]?.label
      : "Greater+Accra+Region";

    const type = filters.type ? filters.type : "room";
    const sort = filters.sorts ? filters.sorts : "asc";
    const cityLabel = filters.selectedCity ? filter1[0]?.label : "";
    queryParams.push(`region=${stateLabel}`);
    queryParams.push(`type=${type}`);
    queryParams.push(`sort=${sort}`);
    if (cityLabel) queryParams.push(`district=${cityLabel}`);

    const url = `/listing-user?${queryParams.join("&")}`;
    axiosData
      .get(url)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };