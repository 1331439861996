/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

function BannerHome({ id, image, link }) {
  const scrolltop = () => {
    localStorage.removeItem("adSeen");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 1000);
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id={id}
        tabindex="-1"
        role="dialog"
        aria-labelledby={id}
        aria-hidden="true"
        style={{ marginTop: "-42px" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div
            className="modal-content"
            style={{ height: "597px", background: "none" }}
          >
            <div className="modal-header" style={{ background: "black" }}>
              <button
                onClick={() => scrolltop()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="">
              <a href={link} target="_blank">
                <img style={{ width: "100%", height: "540px" }} src={image} />
              </a>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => scrolltop()}
                className="btn btn--primary "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerHome;
